(<template>
  <feedback-section :title="title"
                    :icon="'travel'"
                    :progress-active="progressActive"
                    :disable-button="!receiptConfirm"
                    :hide-button="!isChoosenTravelTypeForCancelledAssignment"
                    :hint-text="hintText"
                    class="did-travel-section"
                    @save="save">
    <div class="did-travel-section__btns-wrapper">
      <button class="travel-rates__btn"
              :class="{'travel-rates__btn--active': cancelledAssignmentTravelByPublicTransport}"
              @click="handleCancelledAssignmentTravelType('public_transport')">
        <span class="travel-rates__btn-text travel-rates__btn-text--public_transport">{{ $gettext('Public Transport') }}</span>
      </button>
      <button class="travel-rates__btn"
              :class="{'travel-rates__btn--active': cancelledAssignmentTravelByDriving}"
              @click="handleCancelledAssignmentTravelType('car')">
        <span class="travel-rates__btn-text travel-rates__btn-text--car">{{ $gettext('Driving') }}</span>
      </button>
    </div>
    <div v-if="isChoosenTravelTypeForCancelledAssignment">
      <div v-if="cancelledAssignmentTravelByPublicTransport"
           class="travel-costs__row travel-costs__row--input">
        <p class="travel-costs__title">
          <sk-tooltip :item-text="getTravelTimeTooltipText"
                      class="travel-costs__tooltip" />
          {{ $gettext('Travel time to and from:') }}
        </p>
        <div class="travel-costs__input-wrapper">
          <sk-input :placeholder="'0'"
                    :preselected-value="travelTime"
                    :reg-exp="/[^0-9]/g"
                    :mob-numeric-keyboard="true"
                    :validation-name="errors.travelDurationValidation.name"
                    :max-length="2"
                    class="travel-costs__input"
                    @fieldvaluechanged="setTravelDuration" />
          <span class="travel-costs__currency">{{ $gettext('Hr(s)') }}</span>
        </div>
      </div>
      <div v-if="cancelledAssignmentTravelByDriving">
        <div class="travel-costs__row travel-costs__row--input">
          <p class="travel-costs__title">
            <sk-tooltip :item-text="getDistanceTooltipText"
                        class="travel-costs__tooltip" />
            {{ $gettext('Distance to and from:') }}
          </p>
          <div class="travel-costs__input-wrapper">
            <sk-input :placeholder="'0'"
                      :preselected-value="travelDistance"
                      :reg-exp="/[^0-9]/g"
                      :mob-numeric-keyboard="true"
                      :validation-name="errors.travelDistanceValidation.name"
                      :max-length="6"
                      class="travel-costs__input"
                      @fieldvaluechanged="setTravelDistance" />
            <span class="travel-costs__currency">{{ $gettext('Km(s)') }}</span>
          </div>
        </div>
        <div class="travel-costs__row travel-costs__row--input">
          <p class="travel-costs__title">
            <sk-tooltip :item-text="getDrivingTimeTooltipText"
                        class="travel-costs__tooltip" />
            {{ $gettext('Driving time to and from:') }}
          </p>
          <div class="travel-costs__input-wrapper">
            <sk-input :placeholder="'0'"
                      :preselected-value="travelTime"
                      :reg-exp="/[^0-9]/g"
                      :mob-numeric-keyboard="true"
                      :validation-name="errors.travelDurationValidation.name"
                      :margin-bottom="false"
                      :max-length="2"
                      class="travel-costs__input"
                      @fieldvaluechanged="setTravelDuration" />
            <span class="travel-costs__currency">{{ $gettext('Hr(s)') }}</span>
          </div>
        </div>
      </div>
      <expense-warning-banner :receipt-confirm="receiptConfirm"
                              :message="message"
                              :confirm-message="confirmMessage"
                              class="travel-expenses__expense-warning"
                              @confrimchanged="setReceiptConfirm" />
    </div>
  </feedback-section>
</template>)

<script>
  import {mapGetters, mapState, mapMutations} from 'vuex';
  import SendFeedbackForCancelledAssignment from '@/mixins/SendFeedbackForCancelledAssignment';
  import WarningBanner from '@/components/shared_components/banners/RecepitWarningBanner';
  import FeedbackSection from '@/components/assignment_components/one_assignment/interactive_info/pt_feedback/FeedbackSection';

  export default {
    components: {
      'feedback-section': FeedbackSection,
      'expense-warning-banner': WarningBanner,
    },
    mixins: [SendFeedbackForCancelledAssignment],
    data() {
      return {
        progressActive: false,
        receiptConfirm: false,
        errors: {
          travelDurationValidation: {name: 'travelDurationError', errors: []},
          travelDistanceValidation: {name: 'travelDistanceError', errors: []},
        }
      };
    },
    computed: {
      ...mapState('FeedbackStore', {
        travelTime: (state) => state.feedbackCancelledAssignment.travelTime || '',
        travelDistance: (state) => state.feedbackCancelledAssignment.travelDistance || '',
        travelType: (state) => state.feedbackCancelledAssignment.travelType,
      }),
      ...mapGetters('AssignmentFlowStore', ['travelCostsPricing']),
      ...mapGetters('OneAssignmentStore', [
        'currentDiscussion',
        'cancelledJobFeedback'
      ]),
      ...mapGetters('FeedbackStore', [
        'cancelledAssignmentTravelByDriving',
        'cancelledAssignmentTravelByPublicTransport',
        'isChoosenTravelTypeForCancelledAssignment'
      ]),
      getTravelTimeTooltipText() {
        return this.$gettext('Enter the amount of hours it will take you to travel to and from the assignment.');
      },
      getDistanceTooltipText() {
        return this.$gettext('The platform automatically calculates how many km the assignment location is from your home and back.');
      },
      getDrivingTimeTooltipText() {
        return this.$gettext('For this assignment the customer has set a maximum travel time they are able to pay.');
      },
      confirmMessage() {
        return this.$gettext('I am familiar with, and agree to be charged NOK 500 in administration fees for misleading or incorrect reporting.');
      },
      message() {
        return this.$gettext('<b>NB!</b> Deliberately erroneous and / or misleading reporting causes additional work.');
      },
      title() {
        return this.$gettext('How did you travel?');
      },
      hintText() {
        return this.$gettext('Choose type of transport');
      },
      maxTravelDuration() {
        return this.travelCostsPricing.maxTravelDuration;
      },
      maxTravelDistance() {
        return this.travelCostsPricing.maxTravelDistance;
      }
    },
    methods: {
      ...mapMutations('FeedbackStore', [
        'setTravelDuration',
        'setTravelDistance',
        'setCancelledAssignmentTravelType'
      ]),
      handleCancelledAssignmentTravelType(travelType) {
        if (travelType != this.travelType) {
          this.errors.travelDurationValidation.errors = [];
          this.errors.travelDistanceValidation.errors = [];
          this.setTravelDuration(null);
          this.setTravelDistance(null);
        }

        this.setCancelledAssignmentTravelType(travelType);
      },
      setReceiptConfirm(value) {
        this.receiptConfirm = value;
      },
      setFeedbackInfo() {
        this.setTravelDuration(this.cancelledJobFeedback.travelTime);
        this.setTravelDistance(this.cancelledJobFeedback.travelDistance);
        this.setCancelledAssignmentTravelType(this.cancelledJobFeedback.travelType);
      },
      validateForm() {
        let isValidForm = true;

        this.errors.travelDurationValidation.errors = [];
        this.errors.travelDistanceValidation.errors = [];
        if (this.travelTime > this.maxTravelDuration && this.cancelledAssignmentTravelByPublicTransport) {
          const template = this.$gettext('Max travel time %{time} hours');
          this.errors.travelDurationValidation.errors.push(this.$gettextInterpolate(template, {time: this.maxTravelDuration}));
          this.$store.commit('ErrorsStore/setError', {
            name: this.errors.travelDurationValidation.name,
            errors: this.errors.travelDurationValidation.errors
          });

          isValidForm = false;
        }

        if (this.travelTime > this.maxTravelDuration && this.cancelledAssignmentTravelByDriving) {
          const template = this.$gettext('Max driving time %{time} hours');
          this.errors.travelDurationValidation.errors.push(this.$gettextInterpolate(template, {time: this.maxTravelDuration}));
          this.$store.commit('ErrorsStore/setError', {
            name: this.errors.travelDurationValidation.name,
            errors: this.errors.travelDurationValidation.errors
          });

          isValidForm = false;
        }

        if (this.travelDistance > this.maxTravelDistance && this.cancelledAssignmentTravelByDriving) {
          const template = this.$gettext('Max driving distance %{time} km');
          this.errors.travelDistanceValidation.errors.push(this.$gettextInterpolate(template, {time: this.maxTravelDistance}));
          this.$store.commit('ErrorsStore/setError', {
            name: this.errors.travelDistanceValidation.name,
            errors: this.errors.travelDistanceValidation.errors
          });

          isValidForm = false;
        }

        if (!this.travelTime) {
          this.errors.travelDurationValidation.errors.push(this.$gettext('Please enter time'));
          this.$store.commit('ErrorsStore/setError', {
            name: this.errors.travelDurationValidation.name,
            errors: this.errors.travelDurationValidation.errors
          });

          isValidForm = false;
        }

        if (this.cancelledAssignmentTravelByDriving && !this.travelDistance) {
          this.errors.travelDistanceValidation.errors.push(this.$gettext('Please enter distance'));
          this.$store.commit('ErrorsStore/setError', {
            name: this.errors.travelDistanceValidation.name,
            errors: this.errors.travelDistanceValidation.errors
          });

          isValidForm = false;
        }

        return isValidForm;
      },
      save() {
        if (this.validateForm()) {
          this.sendFeedback(this.currentDiscussion.id);
        }
      }
    },
    mounted() {
      this.setFeedbackInfo();
    }
  };
</script>

<style scoped src="@/assets/tikktalk/css/travel_rate_job_tab.css"></style>

<style scoped>
.did-travel-section .feedback__toggle-btn {
  margin-right: 4px;
  padding: 5px 10px;
  line-height: 20px;
  white-space: normal;
}

.did-travel-section__btns-wrapper {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 10px;
}

.travel-rates__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-left: 20px;
  padding: 5px;
  border: solid 1px #ff5b24;
  border: solid 1px var(--color-secondary);
  border-radius: 3px;
  background-color: #fff;
  color: #ff5b24;
  color: var(--color-secondary);
}

.travel-rates__btn:first-child {
  margin-left: 0;
}

.travel-costs__input-wrapper .sk-input {
  width: 160px;
  margin-bottom: 0;
  text-align: right;
}

.travel-rates__btn-text {
  display: flex;
  align-items: center;
  justify-content: center;
}

.travel-rates__btn-text::before {
  content: '';
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 4px;
  background-position: center;
  background-repeat: no-repeat;
}

.travel-rates__btn-text--public_transport::before {
  background-image: url(~@/assets/tikktalk/imgs/in_person_assignment_icons/public_transport.svg);
  background-image: var(--image-assignment-in_person-public-icon);
}

.travel-rates__btn-text--car::before {
  background-image: url(~@/assets/tikktalk/imgs/in_person_assignment_icons/driving.svg);
  background-image: var(--image-assignment-in_person-car-icon);
}

.travel-rates__btn:hover .travel-rates__btn-text--public_transport::before,
.travel-rates__btn--active .travel-rates__btn-text--public_transport::before {
  background-image: url(~@/assets/tikktalk/imgs/in_person_assignment_icons/public_transport_active.svg);
  background-image: var(--image-assignment-in_person-public-active-icon);
}

.travel-rates__btn:hover .travel-rates__btn-text--car::before,
.travel-rates__btn--active .travel-rates__btn-text--car::before {
  background-image: url(~@/assets/tikktalk/imgs/in_person_assignment_icons/driving_active.svg);
  background-image: var(--image-assignment-in_person-car-active-icon);
}

.travel-rates__btn:hover,
.travel-rates__btn--active {
  border: solid 1px #ff5b24;
  border: solid 1px var(--color-secondary);
  background-color: #ff5b24;
  background-color: var(--color-secondary);
  color: #fff;
}
</style>
