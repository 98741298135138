import { render, staticRenderFns } from "./TravelFeedbackCancelledSection.vue?vue&type=template&id=1c3cad2c&scoped=true&"
import script from "./TravelFeedbackCancelledSection.vue?vue&type=script&lang=js&"
export * from "./TravelFeedbackCancelledSection.vue?vue&type=script&lang=js&"
import style0 from "@/assets/tikktalk/css/travel_rate_job_tab.css?vue&type=style&index=0&id=1c3cad2c&prod&scoped=true&lang=css&"
import style1 from "./TravelFeedbackCancelledSection.vue?vue&type=style&index=1&id=1c3cad2c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c3cad2c",
  null
  
)

export default component.exports